import CurrencyModel from "@/base/model/CurrencyModel";

export default class Subsistence {
  constructor() {
    this.id = null;
    this.employee = null;
    this.period = null;
    this.requested = new CurrencyModel();
    this.reason = null;
    this.description = null;
    this.travel = null;
    this.expenseManagement = null;
  }
}
