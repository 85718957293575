var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validator",attrs:{"slim":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.imprest.amount'),"rules":"required|min_value:0|max_value:9999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal.unsigned",modifiers:{"unsigned":true}}],attrs:{"label":_vm.$t('hr.imprest.amount'),"disabled":_vm.loading,"color":"tertiary","clearable":"","error-messages":errors},model:{value:(_vm.subsistence.requested.amount),callback:function ($$v) {_vm.$set(_vm.subsistence.requested, "amount", _vm._n($$v))},expression:"subsistence.requested.amount"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('currency.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-picker',{attrs:{"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.subsistence.requested.code),callback:function ($$v) {_vm.$set(_vm.subsistence.requested, "code", $$v)},expression:"subsistence.requested.code"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.imprest.reason'),"rules":"required|max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.imprest.reason'),"disabled":_vm.loading,"color":"tertiary","clearable":"","counter":512,"error-messages":errors},model:{value:(_vm.subsistence.reason),callback:function ($$v) {_vm.$set(_vm.subsistence, "reason", $$v)},expression:"subsistence.reason"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.imprest.period'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"disabled":_vm.loading,"label":_vm.$t('hr.imprest.period'),"error-messages":errors},model:{value:(_vm.subsistence.period),callback:function ($$v) {_vm.$set(_vm.subsistence, "period", $$v)},expression:"subsistence.period"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('expense-management-picker',{attrs:{"employee":_vm.$store.state.auth.user.id,"disabled":_vm.loading},model:{value:(_vm.subsistence.expenseManagement),callback:function ($$v) {_vm.$set(_vm.subsistence, "expenseManagement", $$v)},expression:"subsistence.expenseManagement"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('global.description'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('global.description'),"disabled":_vm.loading,"color":"tertiary","clearable":"","counter":512},model:{value:(_vm.subsistence.description),callback:function ($$v) {_vm.$set(_vm.subsistence, "description", $$v)},expression:"subsistence.description"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }